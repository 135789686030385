.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu> .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu> .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  color: rgb(0, 0, 0);
  /* border-bottom: 2px solid red; */
}

.ant-menu-inline {
  width: 100%;
  height: 450px;
}

.ant-menu-item-selected {
  background-color: #3E7742 !important;
  color: #FFFCF7 ;
}

.message-blue {
  position: relative;
  margin-left: 20px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #A8DDFD;
  max-width: 200px;
  height: auto;
  text-align: left;
  font: 400 .9em 'Open Sans', sans-serif;
  border: 1px solid #97C6E3;
  border-radius: 10px;
}

.message-orange {
  position: relative;
  margin-bottom: 10px;
  margin-left: calc(100% - 240px);
  padding: 10px;
  background-color: #f8e896;
  max-width: 200px;
  height: auto;
  text-align: left;
  font: 400 .9em 'Open Sans', sans-serif;
  border: 1px solid #dfd087;
  border-radius: 10px;
}

.message-content {
  padding: 0;
  margin: 0;
  height: auto;
}

.message-blue:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid #A8DDFD;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  top: 0;
  left: -15px;
}

.message-blue:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 17px solid #97C6E3;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  top: -1px;
  left: -17px;
}

.message-orange:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 15px solid #f8e896;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  bottom: 0;
  right: -15px;
}

.message-orange:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 17px solid #dfd087;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  bottom: -1px;
  right: -17px;
}

.chatIcon{
  width: 30%;
  margin-top:100px;
}

.ant-input-search-button{
  background-color: #3E7742 !important;
  COLOR:#FFFCF7 ;
}

.wheatBg,.ant-btn{
  background-color: #3E7742 !important;
color:  #FFFCF7 !important;
outline: #FFFCF7 !important;
}
.ant-btn{
  background-color: #3E7742 !important;
  color: #FFFCF7  !important;
  outline: none !important;
  border: 1px solid #3E7742 !important;
}

.ant-btn-primary {
  border: 1px solid #3E7742 !important;
}

.anticon-bars{
  color: #FFFCF7 !important;
  font-size: 30px !important;
  margin-right: 10px !important;
}
.anticon-bars:hover{
  color: wheat !important;
cursor: pointer;
}



.ant-drawer-content-wrapper{
  width: 300px !important;
  /* overflow-y: auto !important; */
}

.ant-drawer-body {
  padding: 0px !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}




.d-flex{
  display: flex !important;
  align-items: center;
}

.chat_input{
  border-radius: 30px !important;
  margin: 2px !important;
}

.relative_position{
  position: relative;
  padding-bottom: 50px ;
  width: 100% !important;

}
.absolute_position{
  position: absolute;
  bottom: 0;
  width: 100%;
}
.chat_input:focus,.chat_input:hover{
  border-color: #F7DA5C !important;
  box-shadow: 0 0 0 2px rgba(248, 219, 0, 0.2) !important;
  border-right-width: 1px !important;
  outline: 0;
}

.absolutes{
  position: fixed;
  left: 0;
  top: 50%;
  background-color: #3E7742;
  padding: 2px 11px;
  font-size: 30px;
  z-index: 10000;
  border-radius: 0px 15px 15px 0px;
  transition: .1s ease-in;
  color: #FFFCF7 ;
}
.absolutes:hover{
  background-color: #234b26;
  cursor: pointer;
}


@media (max-width:992px) {
  .HeaderIcons{
  display: none !important;
  }
}
@media (max-width:768px) {
  .searchBar{
  display: none !important;
  }
}
@media (min-width:992px) {
  .menuIcon{
    display: none !important;
  }
}
@media (min-width:768px) {
  .responsiveSearhbar{
    display: none !important;
  }
}
.menuIcon{
  font-size: 30px !important;
  margin-top: 5px !important;
}

.d-flex-menu{
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
  margin: 10px !important;
}


.LimitedText{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 20px;     /* fallback */
  max-height: 32px;      /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.ant-btn, .ant-btn:active, .ant-btn:focus {
  outline: 0;
  background-color:#3E7742 !important;
  color :#FFFCF7  !important;
}
.ant-drawer-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #FFFCF7 !important;
  pointer-events: auto;
}